<template>
  <div>
    <v-container fluid>
      <v-row flush>
        <v-col>
          <v-container style="max-width: 1440px">
            <v-row>
              <v-col>
                <h1 class="bb-title">
                  <span style="font-weight: 100">Bruce</span> Bowman
                </h1>

                <div id="bb-static-header" class="d-flex">
                  <v-btn
                    text
                    dark
                    tile
                    @click="$vuetify.goTo('#work', { offset: 30 })"
                  >
                    WORK
                  </v-btn>
                  <v-btn text dark tile @click="$vuetify.goTo('#about')">
                    ABOUT
                  </v-btn>
                  <v-btn text dark tile @click="$vuetify.goTo('#contact')">
                    CONTACT
                  </v-btn>
                </div>

                <v-divider dark />
              </v-col>
            </v-row>
          </v-container>
        </v-col>
      </v-row>

      <v-row flush id="work">
        <v-col>
          <v-container style="max-width: 1440px">
            <v-row>
              <v-col cols="auto" class="d-flex align-center">
                <h4
                  class="bb-art-header"
                  :class="{ 'bb-art-header--dim': activeCategory }"
                  @click="activeCategory ? resetRoutes() : null"
                >
                  CATEGORIES
                </h4>
                <v-scroll-x-reverse-transition>
                  <h4 v-if="activeCategory" class="bb-art-header ml-2">
                    <span class="font-weight-thin">//</span>
                    {{ activeCategory.name }}
                  </h4>
                </v-scroll-x-reverse-transition>
              </v-col>
            </v-row>
          </v-container>
        </v-col>
      </v-row>

      <v-row v-if="working" flush style="min-height: 425px">
        <v-col>
          <v-container style="max-width: 1440px">
            <v-row>
              <v-col class="text-center">
                <v-progress-circular indeterminate color="#ffffff" size="100" />
              </v-col>
            </v-row>
          </v-container>
        </v-col>
      </v-row>

      <template v-else>
        <v-row v-if="!activeCategory" flush style="min-height: 425px">
          <v-col>
            <v-container style="max-width: 1440px">
              <v-row>
                <v-col
                  cols="12"
                  sm="6"
                  md="4"
                  lg="3"
                  v-for="(category, i) in categories"
                  :key="i"
                >
                  <v-card style="height: 100%" color="#111111" flat>
                    <v-card-text
                      class="text-center d-flex flex-column"
                      style="height: 100%"
                    >
                      <v-img
                        eager
                        :src="
                          category.cover_image ||
                          'https://via.placeholder.com/300'
                        "
                        class="mb-2"
                        style="
                          width: auto;
                          max-width: 100%;
                          max-height: 340px;
                          cursor: pointer;
                        "
                        @click="
                          activeCategory = category;
                          fetchWorks(activeCategory.id);
                        "
                      />
                      <h4 style="font-size: 18px; color: #ffffff">
                        {{ category.name }}
                      </h4>
                    </v-card-text>
                  </v-card>
                </v-col>
              </v-row>
            </v-container>
          </v-col>
        </v-row>

        <v-row v-else flush style="min-height: 425px">
          <v-col>
            <v-container style="max-width: 1440px">
              <v-row>
                <v-col
                  cols="12"
                  sm="6"
                  md="4"
                  lg="3"
                  v-for="(work, i) in works"
                  :key="i"
                >
                  <v-card style="height: 100%" color="#111111" flat>
                    <v-card-text
                      class="text-center d-flex flex-column"
                      style="height: 100%"
                    >
                      <v-img
                        eager
                        :src="work.image"
                        class="mb-2"
                        style="
                          width: auto;
                          max-width: 100%;
                          max-height: 340px;
                          cursor: pointer;
                        "
                        @click="showWork(work)"
                      />
                      <h4 style="font-size: 18px; color: #ffffff">
                        {{ work.name }}
                      </h4>
                      <h4 class="caption" style="color: #ffffff">
                        {{ work.category }}
                      </h4>
                      <div
                        style="
                          max-height: 64px;
                          overflow: hidden;
                          display: inline-block;
                          text-overflow: ellipsis;
                        "
                      >
                        <p style="font-size: 16px; color: #ffffff">
                          {{ work.description }}
                        </p>
                      </div>
                    </v-card-text>
                  </v-card>
                </v-col>
              </v-row>
            </v-container>
          </v-col>
        </v-row>
      </template>

      <v-row id="about" flush style="background-color: #ffffff">
        <v-col>
          <v-container style="max-width: 1440px" class="my-6">
            <v-row>
              <v-col>
                <h4 class="bb-art-header bb-art-header--light mb-8">ABOUT</h4>

                <div v-if="infoWorking" class="text-center">
                  <v-progress-circular
                    indeterminate
                    color="#222222"
                    size="100"
                    style="margin: 0 auto"
                  />
                </div>

                <p
                  v-else
                  v-html="info"
                  style="white-space: pre-wrap"
                  class="bb-bio"
                />
              </v-col>
            </v-row>
          </v-container>
        </v-col>
      </v-row>

      <v-row id="contact" flush>
        <v-col>
          <v-container style="max-width: 1440px" class="my-8">
            <v-row>
              <v-col>
                <h4 class="bb-art-header">CONTACT</h4>
              </v-col>
            </v-row>

            <v-row>
              <v-col>
                <div
                  class="bb-contact-form"
                  :class="{ 'bb-contact-form--submitted': contactSubmitted }"
                >
                  <v-scroll-x-reverse-transition>
                    <div
                      style="
                        width: 500px;
                        min-width: 325px;
                        max-width: 100%;
                        position: absolute;
                      "
                      v-if="!contactSubmitted"
                    >
                      <v-text-field
                        v-model="contact.name"
                        dark
                        label="Name*"
                        :rules="[rules.required]"
                      />
                      <div class="d-flex">
                        <v-select
                          v-model="contact.contact_preference"
                          style="max-width: 170px"
                          dark
                          label="Preferred Contact Method"
                          :items="[
                            { text: 'Email', value: 0 },
                            { text: 'Phone', value: 1 },
                          ]"
                        />
                        <v-spacer />
                        <v-text-field
                          v-if="contact.contact_preference == 0"
                          v-model="contact.email"
                          dark
                          label="Email*"
                          :rules="[rules.required, rules.email]"
                        />
                        <v-text-field
                          v-if="contact.contact_preference == 1"
                          v-model="contact.phone"
                          :rules="[rules.required]"
                          dark
                          label="Phone*"
                        />
                      </div>

                      <v-textarea
                        outlined
                        v-model="contact.note"
                        dark
                        placeholder="Optional"
                        label="Note"
                        class="mt-2"
                      />

                      <v-btn
                        dark
                        @click="submitContactInfo"
                        :loading="contactWorking"
                        :disabled="!validContact"
                        >Submit</v-btn
                      >
                    </div>

                    <v-banner
                      v-else
                      dark
                      rounded
                      outlined
                      color="grey"
                      style="
                        width: 500px;
                        min-width: 325px;
                        position: absolute;
                        box-shadow: 5px 5px 0 0 rgba(255, 255, 255, 1);
                      "
                    >
                      <template #icon>
                        <v-icon icon="mdi-lock" dark>
                          mdi-{{
                            contact.contact_preference == 0
                              ? "mail"
                              : "cellphone-sound"
                          }}
                        </v-icon>
                      </template>
                      Thanks! I'll be in touch.
                    </v-banner>
                  </v-scroll-x-reverse-transition>
                </div>
              </v-col>
            </v-row>
          </v-container>
        </v-col>
      </v-row>

      <v-row flush>
        <v-col>
          <v-container style="max-width: 1440px">
            <v-row>
              <v-col>
                <v-divider dark />
                <v-divider dark class="mt-1" />
                <v-divider dark class="mt-2" />
                <v-divider dark class="mt-3" />
                <v-divider dark class="mt-4" />
              </v-col>
            </v-row>
          </v-container>
        </v-col>
      </v-row>

      <v-row flush>
        <v-col>
          <v-container style="max-width: 1440px">
            <v-row>
              <v-col>
                <div class="bb-footer">
                  <h4>Watkinsville, GA | {{ currentYear }}</h4>
                </div>
              </v-col>
            </v-row>
          </v-container>
        </v-col>
      </v-row>
    </v-container>

    <v-dialog
      v-model="dialogOpen"
      max-width="820"
      :overlay-opacity="0.9"
      overlay-color="black"
    >
      <v-card color="rgba(0, 0, 0, .15)">
        <v-card-title>
          <h4 style="color: #ffffff">{{ activeWork.name }}</h4>

          <v-spacer />

          <v-btn @click="dialogOpen = false" icon dark
            ><v-icon>mdi-close</v-icon></v-btn
          >
        </v-card-title>

        <v-card-text>
          <v-img eager :src="activeWork.image_full" contain />
          <h3 class="mt-2" style="color: #ffffff">
            {{ activeWork.description }}
          </h3>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import axios from "axios";
import dayjs from "dayjs";

export default {
  data() {
    return {
      works: [],
      dialogOpen: false,
      categoryDialogOpen: false,
      activeWork: {},
      working: false,
      categories: [],
      activeCategory: null,
      info: null,
      infoWorking: false,
      contact: {
        contact_preference: 0,
      },
      contactWorking: false,
      contactSubmitted: false,
      emailTestVal:
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
      rules: {
        required: (value) => !!value || "Required.",
        email: (value) => {
          return this.emailTestVal.test(value) || "Invalid e-mail.";
        },
      },
    };
  },

  mounted() {
    this.fetchInfo();

    let category = this.$route.query.category;
    if (category) {
      this.fetchCategories().then(() => {
        this.activeCategory = this.categories.find((x) => x.id == category);
        this.fetchWorks(category);
      });
    } else {
      this.fetchCategories();
    }
  },

  computed: {
    currentYear() {
      return dayjs().format("YYYY");
    },

    validContact() {
      let name = this.contact.name ? this.contact.name.trim() : null;
      let phone = this.contact.phone ? this.contact.phone.trim() : null;
      let email = this.contact.email ? this.contact.email.trim() : null;
      let note = this.contact.note ? this.contact.note.trim() : null;

      if (!name || name == "") {
        return false;
      }

      if (this.contact.contact_preference == 0 && !email) {
        return false;
      }

      if (
        this.contact.contact_preference == 0 &&
        !this.emailTestVal.test(email)
      ) {
        return false;
      }

      if (this.contact.contact_preference == 1 && !phone) {
        return false;
      }

      return true;
    },
  },

  methods: {
    fetchInfo() {
      this.infoWorking = true;

      return axios
        .get(process.env.VUE_APP_API_BASE + "/info")
        .then(({ data }) => {
          this.info = data.data;
          this.infoWorking = false;
        });
    },

    submitContactInfo() {
      if (!this.validContact) {
        return;
      }

      this.contactWorking = true;

      return axios
        .post(process.env.VUE_APP_API_BASE + "/contact-request", {
          contact_request: this.contact,
        })
        .then(({ data }) => {
          this.contactSubmitted = true;
          this.contactWorking = false;
        });
    },

    fetchWorks(categoryId) {
      this.working = true;

      if (this.$route.query.category != this.activeCategory.id) {
        this.$router.push({
          path: "/",
          query: { category: this.activeCategory.id },
        });
      }

      return axios
        .get(process.env.VUE_APP_API_BASE + "/works", {
          params: {
            category: categoryId,
          },
        })
        .then(({ data }) => {
          this.works = data.data;
          this.working = false;
        });
    },

    fetchCategories() {
      this.working = true;

      return axios
        .get(process.env.VUE_APP_API_BASE + "/categories")
        .then(({ data }) => {
          this.categories = data.data;
          this.working = false;
        });
    },

    showWork(work) {
      this.activeWork = work;
      this.dialogOpen = true;
    },

    resetRoutes() {
      this.activeCategory = null;

      if (this.$route.query.category) {
        this.$router.push({
          path: "/",
          query: {},
        });
      }
    },
  },

  watch: {
    $route: {
      handler: function (newRoute) {
        if (Object.keys(newRoute.query).length <= 0) {
          this.resetRoutes();
        }
      },
      deep: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.bb-title {
  color: white;
  font-size: 10vw;

  @media only screen and (min-width: 1100px) {
    font-size: 110px;
  }
}

.bb-art-header {
  transition: opacity 0.25s ease-in-out;
  color: white;
  font-size: 22px;
  font-weight: 900;
  text-transform: uppercase;
  cursor: default;

  &.bb-art-header--dim {
    opacity: 0.25;
    cursor: pointer;

    &:hover {
      opacity: 1;
    }
  }

  &.bb-art-header--light {
    color: #444444;
  }
}

.bb-disabled {
  pointer-events: none;
}

.bb-footer {
  color: white;
}

.bb-bio {
  white-space: pre-wrap;
  font-size: 16px;
  color: #444444;
}

.bb-contact-form {
  transition: height 0.5s ease-in-out;
  position: relative;
  height: 350px;

  &.bb-contact-form--submitted {
    height: 100px;
  }
}
</style>
