import Vue from "vue";
import VueRouter from "vue-router";
import routes from "./routes";

Vue.use(VueRouter);

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  linkActiveClass: "active",
  routes,
});

router.afterEach((to, from) => {
  Vue.nextTick(() => {
    document.title = "BruceBowmanArt";
  });
});

export default router;
