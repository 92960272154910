<template>
  <v-app>
    <v-main>
      <v-scroll-y-reverse-transition>
        <div v-if="stickyNavVisible" class="bb-sticky-header">
          <div class="bb-sticky-header__inner">
            <v-btn
              large
              text
              dark
              tile
              @click="$vuetify.goTo('#work', { offset: 30 })"
            >
              WORK
            </v-btn>
            <v-btn large text dark tile @click="$vuetify.goTo('#about')">
              ABOUT
            </v-btn>
            <v-btn large text dark tile @click="$vuetify.goTo('#contact')">
              CONTACT
            </v-btn>
          </div>
        </div>
      </v-scroll-y-reverse-transition>
      <router-view />
    </v-main>
  </v-app>
</template>

<script>
export default {
  name: "App",

  data() {
    return {
      stickyNavVisible: false,
    };
  },

  created() {
    window.addEventListener("scroll", this.checkScrollDistance);
  },

  methods: {
    checkScrollDistance($event) {
      let staticHeaderOffset =
        document.getElementById("bb-static-header").offsetTop;

      if (window.scrollY <= staticHeaderOffset) {
        this.stickyNavVisible = false;
      } else {
        this.stickyNavVisible = true;
      }
    },
  },
};
</script>

<style lang="scss">
.v-application--wrap {
  background-color: #111111;
}

.bb-sticky-header {
  background-color: rgba(0, 0, 0, 0.9);
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 5;

  .bb-sticky-header__inner {
    width: 100%;
    max-width: 1440px;
    top: 0;
    left: 0;
    right: 0;
    margin: auto;
  }
}
</style>
