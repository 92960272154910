import { Home } from "../views";

const routes = [
  {
    path: "/",
    name: "home",
    component: Home,
  },
];

export default routes.map((route) => {
  if (!route.props) {
    route.props = (route) => ({
      ...route.query,
      ...route.params,
    });
  }

  return route;
});
